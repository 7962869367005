import * as util from './index'

export const getVimeoVideoID = (url) => {
  const videoIDMatches = url.match(/(vimeo.com\/)(video\/)?(\d+)/)
  if (!videoIDMatches) {
    console.warn(`Could not extract Vimeo ID from url ${url}`)
    return false
  }

  return videoIDMatches.pop()
}

export const vimeoRequest = (method, endpoint, body) => {
  const headers = new Headers()
  headers.append('Authorization', `bearer ${process.env.GATSBY_VIMEO_TOKEN}`)
  if (method === 'POST') {
    headers.append('Accept', 'application/json')
    headers.append('Content-Type', 'application/json')
  }

  const requestOptions = { headers, method }

  if (body) {
    requestOptions.body = JSON.stringify(body)
  }

  const url = `https://api.vimeo.com/${endpoint}`
  return new Promise((resolve, reject) => {
    fetch(url, requestOptions).then((res) => res.json()).then(json => {
      if (json.error) {
        reject(json.developer_message)
      }
  
      resolve(json);
    });
  })
}

export const requestAnimatedThumbnailCreation = (videoID) => {
  return vimeoRequest('POST', `videos/${videoID}/animated_thumbsets`, {
    video_id: videoID,
    duration: '6',
  })
}

export const getAnimatedThumbnails = (videoID, requestCreationIfNoneFound = true) => {
  return new Promise((resolve, reject) => {
    vimeoRequest("GET", `videos/${videoID}/animated_thumbsets`).then((thumbnailData) => {
      // If no thumbnails exist or are even processing, request that they be created.
      // Then, for at least this page load, fallback on the static images.
      if (thumbnailData.total === 0 && requestCreationIfNoneFound) {
        if (requestCreationIfNoneFound) {
          requestAnimatedThumbnailCreation(videoID)
          reject("No animated thumbnails found. Request was made to create them.")
        } else {
          reject("No animated thumbnails found.")
        }
      }

      // If some thumbnails are exist but are still being processed,
      // then fall back on the static images
      const existantThumbnails = thumbnailData.data.filter(
        (thumbnailGroup) => thumbnailGroup.status === 'completed'
      )
      if (existantThumbnails.length === 0) {
        reject("Animated thumbnails are still being processed.")
      }

      // Use the first one in the array: if the user creates new GIFs, they will be added to the front of the list.
      // TODO: can the user author these on Vimeo.com? If yes, do those thumbnails appear here?
      const thumbnailGroupToUse = existantThumbnails[0]
      resolve(thumbnailSizesToSrcset(thumbnailGroupToUse.sizes))
    }).catch((err) => {
      reject(err);
    })
  })
}

export const thumbnailSizesToSrcset = (sizes) => {
  const srcset = sizes
    .reduce((arr, thumbnailSize) => {
      return arr.concat([`${thumbnailSize.link} ${thumbnailSize.width}w`])
    }, [])
    .join(',')
  const src = util.lastArrayItem(sizes).link
  return { srcset, src }
}

export const getStaticThumbnail = (videoID) => {
  return new Promise((resolve, reject) => {
    vimeoRequest("GET", `videos/${videoID}/pictures`).then((data) => {
      if (data.error || data.total === 0) {
        reject("Error getting static thumbnails from Vimeo");
      }
  
      const thumbnailGroupToUse = util.lastArrayItem(data.data)
      resolve(thumbnailSizesToSrcset(thumbnailGroupToUse.sizes))
    })
  })
}
