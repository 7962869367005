import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import * as cloudinary from '../../utilities/cloudinary'

export const GatsbyImageFromStrapi = ({
  image,
  transformations = [],
  layoutsByBreakpoint = undefined,
  ...props
}) => {
  const parsedImage = cloudinary.parseCloudinaryUrlFromStrapiImage(image)

  // If the transformations include a change to the aspect ratio,
  // we want to use that instead of the ratio we parsed from Strapi
  if (transformations.some((t) => t.includes('ar_'))) {
    const cropTransfomation = transformations.find((t) => t.includes('ar_'))
    const [width, height] = cropTransfomation.split('ar_').pop().split(':')
    parsedImage.aspectRatio = parseInt(width) / parseInt(height)
  }

  const sources = cloudinary.getSourcesFromStrapiImage(
    parsedImage,
    transformations,
    layoutsByBreakpoint
  )

  const thumbnailSource = {
    src: cloudinary.transformStrapiImage(parsedImage, [
      ...transformations,
      cloudinary.getResizeTransformation(100),
    ]),
    type: parsedImage.mime,
    size: '100w',
  }

  const gatsbyImageData = {}
  gatsbyImageData.layout = 'fullWidth'
  gatsbyImageData.backgroundColor = '#f8e8e8'
  gatsbyImageData.width = parsedImage.aspectRatio
  gatsbyImageData.height = 1
  gatsbyImageData.images = {}
  gatsbyImageData.images.fallback = {}
  gatsbyImageData.images.fallback.src = thumbnailSource.src
  gatsbyImageData.images.fallback.srcSet = `${thumbnailSource.src} 100w`
  gatsbyImageData.images.fallback.sizes = '100w'
  gatsbyImageData.images.sources = sources.map((source) => {
    return {
      srcSet: source.src,
      media: source.media,
    }
  })

  return (
    <GatsbyImage
      image={gatsbyImageData}
      alt={image.alternativeText}
      {...props}
    />
  )
}
