import React from 'react';
import { Link } from 'gatsby';
import * as styles from "./index.module.css"

const Pager = ({ pageContext }) => {
  const { previousPagePath, nextPagePath } = pageContext;
  return (
    <nav aria-label="Pagination" className={`${styles.pager} heading`}>
      {previousPagePath && (
        <Link to={previousPagePath} className={styles.next}>Newer</Link>
      )}
      {nextPagePath && (
        <Link to={nextPagePath} className={styles.previous}>Older</Link>
      )}
    </nav>
  );
};

export default Pager;