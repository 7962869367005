import * as React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../layout'
import Pager from '../pager'
import SEO from '../seo'
import { CategoryGridItem } from '../category-grid-item'
import * as styles from './index.module.css'
import * as util from '../../utilities'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const CategoryPage = ({ data, pageContext, location }) => {
  return (
    <Layout breadcrumbText={data.strapiCategory.name}>
      <SEO
        title={data.strapiCategory.name}
        pageSEO={data.strapiCategory.SEO}
      />

      <div className={`${styles.page} page-content--grow`}>
        {data.strapiCategory.description && (
          <div className={[styles.description].join(' ')}>
            <Markdown
              children={data.strapiCategory.description}
              remarkPlugins={[remarkGfm]}
            />
          </div>
        )}
        <div className={styles.inner}>
          {data.allStrapiProject.edges.map((document) => {
            const project = document.node
            return (
              <div className={styles.project} key={document.node.id}>
                <Link to={`/${project.slug}`}>
                  <CategoryGridItem project={project} />
                </Link>
              </div>
            )
          })}
        </div>
      </div>

      <Pager pageContext={pageContext} />
    </Layout>
  )
}

export default CategoryPage

// TODO: can we return a single image?
export const pageQuery = graphql`
  query ($categorySlug: String!, $skip: Int!, $limit: Int!) {
    allStrapiProject(
      filter: { category: { slug: { eq: $categorySlug } } }
      skip: $skip
      limit: $limit
      sort: { fields: [date], order: DESC }
    ) {
      edges {
        node {
          id
          createdAt
          title
          slug
          SEO {
            metaDescription
            keywords
            shareImage {
              alternativeText
              ext
              hash
              height
              mime
              name
              size
              url
              width
            }
          }
          images {
            vimeoUrl
            image {
              alternativeText
              ext
              hash
              height
              mime
              name
              size
              url
              width
            }
          }
          thumbnail {
            alternativeText
            ext
            hash
            height
            mime
            name
            size
            url
            width
          }
        }
      }
    }
    strapiCategory(slug: { eq: $categorySlug }) {
      name
      description
    }
  }
`
