import React from 'react'
import { Link } from "gatsby"

export const slugify = (string) => {
  let output = string
  // Trim whitespace
  output = output.replace(/^\s+|\s+$/g, '')
  // All lowercae
  output = output.toLowerCase()

  // Remove accents, swap ñ for n, etc
  const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
  const to = 'aaaaeeeeiiiioooouuuunc------'
  for (let i = 0, l = from.length; i < l; i++) {
    output = output.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }

  return output
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes
}

export const projectMediaType = (media) => {
  if (media.vimeoUrl) {
    return "vimeo"
  } else {
    return "image"
  }
}

export const isExternalLink = (link) => {
  try {
    return window.location.hostname !== new URL(link).hostname;
  } catch (err) {
    return false
  }
};

export const linkToIf = (condition, link, content, linkProps={}) => {
  if (!condition || !link) {
    return content;
  }

  if (isExternalLink(link)) {
    return <a href={link} {...linkProps}>{content}</a>
  } else {
    return <Link to={link} {...linkProps}>{content}</Link>
  }
};

export const lastArrayItem = (arr) => {
  return arr[arr.length - 1];
};
