// TODO: add alt text
// TODO: add CSS class names
// TODO: force aspect ratio
// TODO: fade in animated thumbnail

import React, { useEffect, useState } from 'react'
import * as vimeoUtils from '../../utilities/vimeo'
import * as cloudinaryUtils from '../../utilities/cloudinary'
import { GatsbyImageFromStrapi } from '../gatsby-image-from-strapi'
import * as styles from './index.module.css'

const THUMBNAIL_TYPE_VIMEO = 'vimeo'
const THUMBNAIL_TYPE_CLOUDINARY = 'cloudinary'

const getImageType = (data) => {
  if (data?.srcset) {
    return THUMBNAIL_TYPE_VIMEO
  } else if (data) {
    return THUMBNAIL_TYPE_CLOUDINARY
  } else {
    return null
  }
}

// Given image data, return the appropriate image markup
// For a Cloudinary image, use the gatsby-image component.
// For a Vimeo image, use a standard image tag.
// For everything else, use a standard placeholder <div>
const getImageMarkup = (image) => {
  const imageType = getImageType(image)
  switch (imageType) {
    case THUMBNAIL_TYPE_VIMEO:
      return (
        <picture>
          <source srcSet={image.srcset} src={image.src} />
          <img srcSet={image.srcset} src={image.src} />
        </picture>
      )
    case THUMBNAIL_TYPE_CLOUDINARY:
      return (
        <GatsbyImageFromStrapi
          image={image}
          transformations={[cloudinaryUtils.getCropTransformation('4:5')]}
          layoutsByBreakpoint={{
            xs: 0.5,
            sm: 0.33,
            lg: 0.25
          }}
        />
      )
    default:
      return <div className={styles.placeholderThumbnail}></div>
  }
}

const getThumbnail = (primaryImage, secondaryImage) => {
  const imageType = getImageType(primaryImage)
  switch (imageType) {
    case THUMBNAIL_TYPE_VIMEO:
      const animatedThumbnail = getImageMarkup(primaryImage)
      const fallbackThumbnail = getImageMarkup(secondaryImage)
      return (
        <div className={styles.thumbnail}>
          <div className={styles.animatedThumbnail}>{animatedThumbnail}</div>
          <div className={styles.staticThumbnail}>{fallbackThumbnail}</div>
        </div>
      )
    case THUMBNAIL_TYPE_CLOUDINARY:
      const staticThumbnail = getImageMarkup(primaryImage)
      return (
        <div className={styles.thumbnail}>
          <div className={styles.staticThumbnail}>{staticThumbnail}</div>
        </div>
      )
    default:
      const placeholderThumbnail = getImageMarkup(primaryImage)
      return <div className={styles.thumbnail}>{placeholderThumbnail}</div>
  }
}

export const CategoryGridItem = (props) => {
  const [primaryImage, setPrimaryImage] = useState(null)
  const [secondaryImage, setSecondaryImage] = useState(null)

  useEffect(() => {
    const imageEntryWithVimeo = props.project.images.find((img) => img.vimeoUrl)
    const vimeoUrl = imageEntryWithVimeo && imageEntryWithVimeo.vimeoUrl
    const vimeoID = imageEntryWithVimeo && vimeoUtils.getVimeoVideoID(vimeoUrl)
    const firstProjectImage = props.project.images?.length
      ? props.project.images.find((entry) => entry?.image)?.image
      : null

    if (props.project.thumbnail) {
      // Firstly, if the author has manually set a thumbnail, we need to honor that.
      setPrimaryImage(props.project.thumbnail)
    } else if (imageEntryWithVimeo) {
      // Secondly, if there is a Vimeo link among the project's images, we should
      // attempt to grab an animated thumbnail from it
      vimeoUtils
        .getAnimatedThumbnails(vimeoID)
        .then((thumbnailData) => {
          // If animated thumbnails actually exist, use them as the primary image.
          setPrimaryImage(thumbnailData)

          // We also want a secondary fallback image that can be used while the animated image is loading.
          if (firstProjectImage) {
            // We first look to the Strapi-authored images for this fallback image.
            setSecondaryImage(firstProjectImage)
          } else {
            // If there are no images saved in strapi, but there is a Vimeo URL,
            // we'll grab the vimeo thumbnail.
            vimeoUtils.getStaticThumbnail(vimeoID).then((thumbnailData) => {
              setSecondaryImage(thumbnailData)
            })
          }
        })
        .catch(() => {
          // If there were problems grabbing animated thumbnails, use a static image instead.
          if (firstProjectImage) {
            // We first look to the Strapi-authored images for this static image.
            setPrimaryImage(firstProjectImage)
          } else {
            // If there are no images saved in strapi, but there is a Vimeo URL,
            // we'll grab the vimeo thumbnail.
            vimeoUtils.getStaticThumbnail(vimeoID).then((thumbnailData) => {
              setPrimaryImage(thumbnailData)
            })
          }
        })
    } else if (firstProjectImage) {
      setPrimaryImage(firstProjectImage)
    }
  }, [props.project])

  return (
    <div>
      {getThumbnail(primaryImage, secondaryImage)}
      <p className={`body-italic ${styles.title}`}>{props.project.title}</p>
    </div>
  )
}
